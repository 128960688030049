/* this is global styles for project */

:root {
  --color-white: #ffffff;
  --main-color: #103a5d;
  --gray-text: #adb2b9;
  --disabled-background-color: #e8e7e7;
  --disabled-text-color: #888;
  --pr-count:#09ABE2;
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  text-decoration: none;
}

body {
  font-family: "Sarabun";
  font-style: normal;
}

h1 {
  font-size: 40px;
  color: var(--main-color);
}

h2 {
  font-size: 24px;
  color: var(--main-color);
}

h3 {
  font-size: 18px;
  color: var(--main-color);
}

h4 {
  font-size: 16px;
  color: var(--main-color);
}
h5 {
  font-size: 14px;
  color: var(--main-color);
}
h6 {
  font-size: 12px;
}
p {
  margin-bottom: 0px;
}

button:disabled,
input:disabled,
select:disabled,
textarea:disabled {
  cursor: not-allowed;
  background-color: var(--disabled-background-color) !important;
  color: var(--disabled-text-color) !important;
}
button{
  background: #ffffff;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;

}


input{
  font-family: sans-serif , "Sarabun";
}