.create-press-release {
  color: #103a5d;
  font-family: Sarabun;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  right: 2000px;
  text-transform: capitalize;
}

.create-press-modal{
  height: 80% !important;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer and Edge */
}

/* Webkit-based browsers (Chrome, Safari) */
.create-user-modal::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.press-choose-file-txt {
  color: #fff;
  font-family: Sarabun;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.choose-file-button {
  width: 150px;
  display: flex;
  padding: 5px 30px;
  align-items: center;
  gap: 10px;
  border-radius: 2px;
  background: #103a5d;
  cursor: pointer;
  margin-top: 7px;
}

.choose-file-icon {
  width: 12px;
  height: 10px;
  fill: #fff;
}

.selected-file-container {
  position: relative;
}

.close-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}

.press-photos-p {
  color: #999;
  font-family: Sarabun;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 10px;
}

.selected-files {
  display: flex;
  flex-wrap: wrap;
  /* margin-top: 10px; */
}

.selected-file {
  width: 150px;
  height: 150px;
  border-radius: 12px;
  border: 1px solid #adb2b9;
  background: #fff;
  margin-left: 5px;
  margin-bottom: 5px;
  /* margin-top: 18px; */
}

.press-title {
  width: 1100px;
  border-radius: 5px;
  border: 1px solid #ebebeb;
}
.press-description {
  display: flex;
  gap: 10px;
  width: 100%;
  height: auto;
  min-height: 100px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ebebeb;
  resize: none;
}

.group-dropdown select {
  color: var(--main-color);
}
