.supplier-visit-table-active {
  border-bottom: 2px solid var(--main-color);
  transition: all 0.2s ease-in-out;
  font-family: "Sarabun" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: capitalize;
  text-decoration: none !important;
  color: var(--main-color) !important;
}

.supplier-visit-table {
  font-family: "Sarabun" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: capitalize;
  color: #adb2b9 !important;
  text-decoration: none !important;
}

.supplier-visit-table-badge-active {
  padding: 3px;
  margin-left: 5px;
  background: #103a5d;
  color: white;
  text-decoration: none !important;
  min-width: 20px;
}

.supplier-visit-table-badge {
  margin-left: 5px;
  padding: 2px;
  margin-left: 5px;
  background: #ebebeb;
  color: #999999;
  text-decoration: none !important;
  min-width: 20px;
}

.table-navbar {
  padding-top: 20px;
  margin-bottom: 10px;
  border-bottom: 2px solid #ebebeb;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.table-status {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  border-radius: 5px;
  padding: 5px 10px;
  text-transform: capitalize;
  white-space: nowrap !important;
  color: #ffffff;
}

.approved {
  background: #e0f8f2;
  color: #43d5ae;
}

.rejected {
  background: #fff3f1;
  color: #fd8a75;
}

.newvendor {
  color: #eac52d;
  background: #fcf6dd;
}

.confirming-po {
  color: #428bc1;
  background: #dfe6f1;
}

.waiting-po {
  color: #afca0b;
  background: #f6ffbf;
}

.vm-approve {
  background: #ffce31;
}

.vm-act-sap {
  color: #43d5ae;
  background: #e0f8f2;
}

.vm-status-approve {
  color: #43d5ae;
  background: #e0f8f2;
}
.vm-status-reject {
  color: #fd8a75;
  background-color: #fff3f1;
}
.vm-status-pending {
  color: #afca0b;
  background: #f6ffbf;
}
.vm-reject {
  background: #ff0000;
}

.hvVEbD {
  background-color: #103a5d;
}

.po-expaneded-table-div {
  background: #ebebeb;
  padding: 25px 5px 5px 5px;
}

.po-expanded-table {
  width: 100%;
}

.po-expanded-table th {
  height: 28px;
  background: #999999;
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  text-transform: capitalize;
  color: #ffffff;
}

.po-expanded-table td {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  text-transform: capitalize;
  background-color: white;
  color: #103a5d;
  border-bottom: 1px solid #dee2e6;
  padding-top: 5px;
  padding-bottom: 5px;
}

.po-table button:disabled {
  display: none;
}

.quotation {
 background-color: #FFF3F1;
 color: #FD8A75;
}

.waiting-po {

}

.delivering-job{
  background-color: #DFE6F1;
  color: #428BC1;
}

.delivered {

}