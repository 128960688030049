.login-form {
  width: 50vw;
}
.login-logo-div {
  width: 50%;
   max-height: 100vh; 
  /* overflow: hidden; */
}
.login-logo {
  width: 100%;
  height: auto;
}
