.time-stamp-modal {
  width: auto;
  top: 50%;
  min-width: 300px
}

.ts-time-div {
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  text-transform: capitalize;
  color: #999999;
  white-space: nowrap;
  padding-bottom: 50px;
  padding-top: 10px;
}

.ts-main-scroll {
  max-height: 350px;
  overflow-y: auto;
}

.ts-main-scroll::-webkit-scrollbar {
  width: 20px;
}

.ts-main-scroll::-webkit-scrollbar-track {
  background: #d9d9d9;
  border: 1px solid #999999;
  border-radius: 20px;
}

.ts-main-scroll::-webkit-scrollbar-thumb {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background: #003595;
  border: 1px solid #999999;
  border-radius: 20px;
}
/* .ts-main-scroll::-webkit-scrollbar-thumb:hover {
  background: #555;
} */
.ts-title-close-icon-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ts-title {
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;
  text-transform: capitalize;
  color: var(--main-color);
}

.ts-status {
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  text-transform: capitalize;
  color: var(--main-color);
}

.ts-main-div {
  display: flex;
  /* justify-content: center; */
  align-items: baseline;
  gap: 30px;
}

.ts-vl {
  border-left: 1.5px solid #ebebeb;
  height: 30px;
  margin-left: 20px;
  padding-right: 20px;
}

.ts-img-container {
  position: relative;
}

.ts-vertical-line {
  position: absolute;
  width: 5px;
  height: 100%;
  min-height: 100px;
  background: #d9d9d9;
  left: 50%;
  transform: translateX(-50%);
}

.ts-date {
  padding-left: 10px;
}

.ts-person-icon{
  padding-right: 10px;
}
