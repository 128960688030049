.main-container {
  left: 0px;
  top: 449px;
  width: 100%;
  background: #eff2f4;
}

.form-container {
  left: 150px;
  top: 918px;
  background: #ffff;
  margin: 30px;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 50px;
  padding-bottom: 20px;
}

.header-registration {
  color: var(--main-color);
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  gap: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.header-registration-two {
  color: var(--main-color);
}

.condition {
  color: #999999;
}

.vendor-page-container {
  height: 48px;
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 36px;
  margin: 30px;
  color: var(--main-color);
  padding-bottom: 5px;
  text-transform: capitalize;
}

.vendor-input-label {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
  margin-top: 15px;
  color: var(--main-color);
  margin-bottom: 15px;
}

.textbox-container {
  display: flex;
  width: 100%;
  align-items: center;
}

.left-container {
  width: 100%;
  margin-right: 20px;
}

.left-container-hidden-input {
  width: 49%;
  margin-right: 20px;
}

.left-Username-container {
  width: 100%;
  margin-right: 20px;
}

.input-border {
  border: 1px solid #adb2b9;
  border-radius: 10px;
  width: 50%;
  margin-bottom: 15px;
  height: 40px;
  font-size: 14px;
}

.registration {
  width: 100%;
  color: #000000;
  padding-left: 10px;
}
.registration-max {
  width: 100%;
  color: #000000;
  padding-left: 10px;
  margin-right: 60px;
}
.doesyourcompany {
  width: 100%;
  color: #000000;
  padding-left: 10px;
  margin-top: 27px;
}

/* .registration-max {
  width: 100%;
  color: #000000;
  padding-left: 10px;
  margin-right: 15px;
} */

.right-container {
  width: 100%;
}

.registration-left {
  width: 100%;
  padding-left: 10px;
}

.registration-left-date {
  width: 100%;
  text-align: right;
  padding-right: 10px;
}

.fifty-width {
  width: 25%;
}

.right-container-box {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.rightside-container {
  width: 50%;
  margin-right: 20px;
}

.leftside-container {
  width: 50%;
}

.mini {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 530;
  font-size: 11px;
  line-height: 18px;

  color: #999999;
}

.contact {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  color: var(--main-color);
}

.contact-two {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  color: var(--main-color);
  padding-top: 35px;
}

.registeredaddress {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  margin-top: 30px;
  color: var(--main-color);
}

.staricon {
  color: red;
}

input[disabled] {
  background: #ebebeb;
}

.button-submit-container {
  display: flex;
  justify-content: flex-end;
}

.button-submit {
  width: 200px;
  height: 50px;
  border-radius: 5px;
  background-color: var(--main-color);
  text-align: center;
  color: #ffffff;
  font-family: "Sarabun";
  font-style: normal;
  margin-bottom: 50px;
  font-size: 28px;
}

select {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  color: #c4c5c7;
}

.error-msg {
  color: red;
}

.horziantal {
  width: 100%;
  text-align: left;
  margin-left: 0;
}

.buttons {
  display: flex;
  justify-content: space-between;
}

.button-submit-back {
  height: 66px;
  border-radius: 5px;
  background-color: var(--main-color);
  text-align: center;
  color: #ffffff;
  width: 200px;
  font-family: "Sarabun";
  font-style: normal;
  margin-bottom: 50px;
  margin-left: 30px;
  font-size: 28px;
}

.vendor-input-label-anylitigation {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 23px;
  margin-top: 15px;
  color: var(--main-color);
  margin-bottom: 15px;
}

.form-container-with-bottomspace {
  left: 150px;
  top: 918px;
  background: #ffff;
  margin: 30px;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 50px;
  padding-bottom: 400px;
}

.text-above-the-horizantalline {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  color: var(--main-color);
}

.right-container-inSupplier {
  width: 50%;
  margin-right: 20px;
}

.left-container-inSupplier {
  margin-right: 20px;
}

.maximum-minimum {
  display: flex;
  width: 100%;
  gap: 10px;
  align-items: center;
}

.maximum-minimum-tags {
  display: flex;
  /* gap: 171px; */
  align-items: center;
  /* justify-content: space-between; */
  width: 100%;
  /* padding-bottom: 5px; */
}

.minimum {
  color: var(--main-color);
  width: 54%;
}

.maximum {
  color: var(--main-color);
}

.vendor-input-label-financial-project-ref {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
  margin-top: 15px;
  color: var(--main-color);
  margin-bottom: 38px;
}

.vendor-input-label-hse {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
  margin-top: 15px;
  color: var(--main-color);
  margin-bottom: 15px;
}

.form-container-with-bottomspace-finanacial {
  left: 150px;
  top: 918px;
  background: #ffff;
  margin: 30px;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 50px;
  padding-bottom: 600px;
}

.registration-hse {
  width: 50%;
  color: #000000;
  padding-left: 10px;
}

.button-submit-container-hse {
  display: flex;
  justify-content: flex-end;
}

.button-submit-hse {
  height: 50px;
  border-radius: 5px;
  background-color: #0b1d35;
  /* top:20px; */
  text-align: center;
  color: #ffffff;
  width: 200px;
  font-family: "Sarabun";
  font-style: normal;

  margin-left: 30px;
  font-size: 18px;
}

.button-input-container {
  display: flex;
}

.hse-mini {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  /* width: 520px; */

  color: #999999;
}

.form-container-with-bottomspace-hse {
  left: 150px;
  top: 918px;
  background: #ffff;
  margin: 30px;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 50px;
  padding-bottom: 200px;
}

.right-Username-container {
  width: 100%;
}

.test-user-container {
  display: flex;
}

.button-attach-container-hse {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.button-attach-hse {
  height: 50px;
  border-radius: 5px;
  background-color: #ffffff;
  border: 1px solid var(--main-color);
  /* top:20px; */
  text-align: center;
  color: var(--main-color);
  width: 100%;
  font-family: "Sarabun";
  font-style: normal;
  padding-right: 40px;
  padding-left: 40px;
  margin-left: 30px;
  font-size: 18px;
}

.form-container-with-bottomspace-documents {
  left: 150px;
  top: 918px;
  background: #ffff;
  margin: 30px;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 50px;
  padding-bottom: 250px;
}

.vendor-input-label-terms {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
  margin-top: 15px;
  color: #adb2b9;
  margin-bottom: 15px;
}

.Privacy-notice {
  color: #eb0a45;
}

.dropdown {
  height: 50px;
  border-radius: 5px;
}

.dropdown-hse {
  border: 1px solid #adb2b9;
  background-color: #ffff;
  border-radius: 5px;
  margin-top: -22px;
}

.announce-navbar {
  display: flex;
  justify-content: center;
  align-items: center;
}

.uploadFile-document {
  white-space: nowrap;
  height: 44px;
  border-radius: 5px;
  background-color: #ffffff;
  border: 1px solid var(--main-color);
  /* top:20px; */
  text-align: center;
  color: var(--main-color);
  /* width: 20%; */
  font-family: "Sarabun";
  font-style: normal;
  padding-right: 40px;
  padding-left: 40px;
  margin-left: 30px;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.uploadFile-document-disabled {
  white-space: nowrap;
  height: 44px;
  border-radius: 5px;
  text-align: center;
  font-family: "Sarabun";
  font-style: normal;
  padding-right: 40px;
  padding-left: 40px;
  margin-left: 30px;
  font-size: 18px;
  background-color: var(--disabled-background-color) !important;
  border: 1px solid #303031;
  color: var(--disabled-text-color) !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.uploadFile-document-disabled:hover {
  cursor: not-allowed;
}
.uploadFile-document-supplier-hse {
  height: 44px;
  border-radius: 5px;
  background-color: #ffffff;
  border: 1px solid var(--main-color);
  /* top:20px; */
  text-align: center;
  color: var(--main-color);
  width: 120px;
  font-family: "Sarabun";
  font-style: normal;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.input-border-documents {
  border: 1px solid #adb2b9;
  border-radius: 10px;
  width: 82%;
  margin-bottom: 15px;
  height: 44px;
  padding: 10px;
}

.input-border-documents-supplier-hse {
  border: 1px solid #adb2b9;
  border-radius: 10px;
  width: 75%;
  margin-bottom: 15px;
  height: 44px;
  padding: 10px;
  margin-right: 20px;
}

.textbox-container-specify {
  display: flex;
  justify-content: flex-end;
  margin-left: 20px;
}
.right-container-specify {
  width: 50%;
}

.dropdown-multi {
  border: 1px solid #adb2b9;
  border-radius: 10px;
  height: 40px;
  width: 100%;
  max-width: 465px;
  min-width: 100%;
}

.fake-input {
  width: 100%;
  height: 48px;
  border-radius: 5px;
}

.question-mark {
  width: 30px;
  height: 30px;
}

.custom-select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("../../../Assets/Svg/drop-down-icon.svg");
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) center;
  padding-right: 20px !important;
}

.custom-select::-ms-expand {
  display: none;
}

.dropdown-multi .dropdown .dropdown-toggle::after {
  display: none !important;
}

.dropdown-multi .dropdown .dropdown-toggle span::after {
  content: "";
  position: absolute;
  /* background-image: url("../../../Assets//Svg/drop-down-icon.svg"); */
  top: 50%;
  width: 20px;
  height: 20px;
  right: 10px;
  transform: translateY(-50%);
  background-size: cover;
  background-repeat: no-repeat;
}

/* .dropdown-country{
  color: #C4C5C7;
} */

.btn-light {
  background-color: transparent !important;
  padding: 5px !important;
}

.btn-light:focus {
  outline: none !important;
  box-shadow: none !important;
}

.btn-light span {
  margin-right: -12px !important;
}

/* country code drop down styles */
.react-tel-input .flag-dropdown {
  width: 60px !important;
}
.react-tel-input {
  margin-left: 50px !important;
  width: 90% !important;
}

.react-tel-input .form-control {
  height: 44px !important;
  width: 100% !important;
  border: 1px solid #adb2b9 !important ;
  border-radius: 10px !important;
}

.toggle-password-button{
  background-color: #ffffff;
  padding: 5px;
}

.toggle-password-button img{
  width: 15px;
}
@media screen and (min-width: 768px) and (max-width: 1500px) {
  .dropdown-hse {
    width: 100%;
    max-width: 465px;
  }
}

@media screen and (min-width: 1500px) and (max-width: 1920px) {
  .dropdown-multi {
    width: 100%;
    max-width: 780px;
    min-width: 100%;
  }
  .dropdown-hse {
    width: 100%;
    max-width: 780px!important;
    margin-top: 0px;
    min-width: 100%;
  }
}