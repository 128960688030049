.not-allowed{
  cursor: not-allowed !important;
}

.sb-main {
  width: 280px;
  height: 100%;
  position: fixed;
  overflow-y: scroll;
  scrollbar-width: none; 
  -ms-overflow-style: none;
  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.25));
  background: #fdfdff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  z-index: 1300;
}

.sb-main::-webkit-scrollbar{
  width: 0;
  background: transparent;
}

.sb-logo-div {
  display: flex;
  justify-content: center;
  padding-top: 82px;
  padding-bottom: 50px;
}
.sb-buttons-div {
  display: grid;
  place-items: center;
}

.sb-btn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  margin-top: 10px;
  height: 38px;
  background: #ffffff;
  border-radius: 5px;
  width: 80%;
  font-weight: 500;
  font-size: 16px;
  color: var(--main-color);
  padding-left: 10px;
  cursor: pointer;
}

/* .sb-btn:hover {
  background: var(--main-color);
  color: #ffffff;
} */

.sb-btn-active {
  background: var(--main-color);
  color: #ffffff;
}

.sb-hr {
  height: 2px !important;
  width: 80%;
  background-color: #9999;
  margin: 0px !important;
}

.a-child {
  margin-left: 30px;
  padding-left: 30px;
  /* width: 70%; */
}

/* .a-child:hover {
  background: var(--main-color);
  color: #ffffff;
} */
