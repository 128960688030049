.create-penalty-modal {
  height: 80% !important;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer and Edge */
}

.penalty-title {
  width: 1100px;
  border-radius: 5px;
  border: 1px solid #ebebeb;
}
.penalty-description {
  display: flex;
  gap: 10px;
  width: 100%;
  height: auto;
  min-height: 100px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ebebeb;
  resize: none;
}

.penalty-input{
  max-width: fit-content !important;
  width: fit-content !important;
  border: none !important;
}