.login-form-div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 85vh;
}

.login-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  text-transform: uppercase;
  color: #94a0ad;
}
.login-welcome {
  font-weight: 700;
  font-size: 36px;
  line-height: 47px;
  text-transform: uppercase;
  color: #173a5a;
}
.login-label-input {
  display: block;
}
.login-label {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #5b6770;
}
.login-input {
  margin-top: 5px;
  width: 100%;
  height: 40px;
  background: #ffffff;
  border: 2px solid #5b6770;
  border-radius: 5px;
  padding: 5px;
}

.error-msg {
  color: red;
}

.login-btn {
  margin-top: 5px;
  width: 100%;
  height: 40px;
  background: var(--main-color);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #ffffff;
}

.lg-terms a {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-decoration: none;
  color: #5b6770;
}
