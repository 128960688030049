.vendor-profile-main-div {
  width: 80%;
  margin-left: 260px;
  display: inline-block;
  margin-top: 4rem;
}

.vendor-profile-header {
  display: flex;
  gap: 20px;
}

.vendor-profile-header-text {
  color: var(--main-color);
  font-size: 28px;
  font-weight: bold;
  font-family: "Sarabun";
  font-style: normal;
}

.vendor-profile-nav-active {
  border-bottom: 2px solid #0b1d35;
  transition: all 0.2s ease-in-out;
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: capitalize;
  color: #0b1d35;
}

.vendor-profile-nav {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: capitalize;
  color: rgba(146, 146, 146, 0.952);
}

.vendor-profile-edit {
  background-color: var(--main-color);
  color: #ffffff;
  border: 0px;
  border-radius: 3px;
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 10px;
  height: 30px;
}

.vendor-profile-edit:disabled {
  background: #999999;
  color: #ffffff;
}

.genaral-main-div {
  padding-top: 20px;
}

.line {
  margin-top: -2px;
}

.vendor-profile-contact {
  font-size: 18px;
  color: black;
  font-style: bold;
  font-family: "Sarabun";
  font-weight: 700;
  padding-top: 20px;
  margin-bottom: 15px;
}

.vendor-hse {
  padding-top: 15px;
  margin-bottom: 20px;
  color: #0b1d35;
  font-size: 18px;
  font-style: normal;
  font-family: "Sarabun";
}

.hse-text {
  font-size: 18px;
  color: var(--main-color);
  padding-top: 25px;
  padding-bottom: 20px;
  font-style: normal;
  font-family: "Sarabun";
}

.hse-text-button {
  display: flex;
  justify-content: space-between;
  padding-bottom: 40px;
}

.hse-attach-text {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: var(--main-color);
}

.hse-attach-button {
  background: #ffffff;
  border: 1px solid var(--main-color);
  border-radius: 5px;
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  text-transform: capitalize;
  color: var(--main-color);
}

.show-items-attahed-files {
  display: flex;
  align-items: center;
  border: 1px solid #ebebeb;
  width: 400px;
}

.file-name-attached-file {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: var(--main-color);
}

.vp-title-btn {
  justify-content: flex-start;
  gap: 50px;
}

.vp-label-input {
  display: flex;
  width: 100%;
  border-bottom: 1px solid #adb2b9;
  padding-bottom: 10px;
  padding-top: 10px;
}

.vp-label {
  color: var(--main-color);
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  width: 90%;
  /* white-space: nowrap; */
}

.vp-label-input input {
  padding: 5px !important;
}

.vp-input {
  height: 33px;
  /* width: 60%; */
  background: #ffffff;
  border: 1px solid #adb2b9;
  font-size: 16px;
  line-height: 21px;
  color: #000000;
  padding: 0px 0px 0px 5px;
}

.vp-input-div {
  width: 60%;
  display: grid;
}

.vp-input:disabled {
  border: unset;
  color: #000000;
  font-size: 16px;
  padding: 5px;
}

.vp-btns {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.vp-btns button {
  padding: 5px 50px;
}

#disabled-div {
  pointer-events: none !important;
  cursor: not-allowed;
}

.vp-multi-input-div {
  background-color: var(--disabled-background-color);
  border: unset;
  color: #000000;
  font-size: 16px;
}

.vp-input-div select {
  appearance: none !important;
  background: url("../../Assets/Svg/down-arrow.svg") no-repeat calc(100% - 10px)
    center;
  padding-left: 10px;
  padding-right: 10px;
}

.vp-min-max-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 60%;
}

.vp-min-label {
  color: var(--main-color);
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  padding-right: 10px;
}

.vp-supplier-multi div {
  max-width: 100% !important;
}

/* Summary page styles */

.vp-sum-label-input {
  display: flex;
  width: 100%;
  padding-bottom: 10px;
  padding-top: 10px;
  align-items: center;
}

.vp-sum-input-div {
  position: relative;
}

.vp-lock {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

.lock-icon {
  width: 20px;
  height: 20px;
}

.vp-sum-dropdown {
  background-image: url("../../Assets/Images/sum-drop.png") !important;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 30px 30px;
  width: 135px;
  padding: 5px;
}

.vp-sum-dropdown::-ms-expand {
  display: none;
}

.vp-sum-label {
  color: var(--main-color);
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
}

.vp-sum-checkbox {
  width: 18px;
  height: 18px;
  background: #999999;
  margin-left: 10px;
}

.vp-sum-check-div {
  display: flex;
  justify-content: space-between;
  width: 50%;
  margin-bottom: 20px;
}

.vp-sum-check-div div {
  display: flex;
  align-items: center;
}
.vp-sum-title {
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  color: #000000;
  margin-bottom: 20px;
}
.sum-title {
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  color: var(--main-color);
}
.vp-textarea {
  width: 100%;
  min-height: 100px;
  height: auto;
  margin-bottom: 20px;
}

/* input[type="checkbox"]{
accent-color:#AFCA0B;
outline :red;

} */
.vp-sum-checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 2px solid #999999;
  /* border-radius: 4px; */
  width: 18px;
  height: 18px;
  position: relative;
  outline: none;
  cursor: pointer;
  background-color: white;
}

.vp-sum-checkbox:checked {
  background-color: white;
}

.vp-sum-checkbox::after {
  content: "";
  position: absolute;
  border: 2px solid white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  width: 20px;
  height: 20px;
  background-image: url("../../Assets/Images/green-check.png");
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 1;
  /* transition: transform 0.2s ease-in-out; */
}

.vp-sum-checkbox:checked::after {
  transform: translate(-50%, -50%) scale(1);
}

.vp-supplier-multi-select div{
  cursor: not-allowed;
  background-color: var(--disabled-background-color) !important;
  color: var(--disabled-text-color) !important;
}

.disabled-dropdown-multiselect span {
  color:var(--disabled-text-color);
}