.back-button {
  color: #103a5d;
  font-size: 24px;
  font-family: "Sarabun";
  cursor: pointer;
  font-weight: 700;
  text-transform: capitalize;
  word-wrap: break-word;
  display: flex;
  align-items: center;
  gap: 10px;
}
/* .qtn-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 30px;
} */

/* .qtn-cont-section {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
} */

/* .qtn-text-block {
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 15px;
} */

/* .title {
  color: #103a5d;
  font-size: 18px;
  font-family: Sarabun;
  font-weight: 400;
  word-wrap: break-word;
} */

/* .date-input {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  gap: 120px;
  padding: 10px;
  background: white;
  border-radius: 5px;
  border: 0.5px #adb2b9 solid;
} */

/* .date-input input[type="date"] {
  color: #999999;
  font-size: 18px;
  font-family: Sarabun;
  font-weight: 400;
} */

/* .vendor-code {
  align-self: stretch;
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 15px;
} */

/* .vendor-code-text {
  color: #103a5d;
  font-size: 18px;
  font-family: Sarabun;
  font-weight: 400;
  word-wrap: break-word;
} */

/* .vendor-input {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 120px;
  padding: 10px;
  background: white;
  border-radius: 5px;
  border: 0.5px #adb2b9 solid;
}

.vendor-input input[type="text"] {
  color: #999999;
  font-size: 18px;
  font-family: Sarabun;
  font-weight: 500;
  text-transform: capitalize;
} */
.quotation-pr-title {
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  margin: 30px 3px;
}

/* .quotation-pr-title-text {
  color: #003595;
  font-size: 20px;
  font-family: Sarabun;
  font-weight: 400;
  text-transform: capitalize;
  word-wrap: break-word;
} */
.quotation-pr-note-text {
  color: #103a5d;
  font-family: Sarabun;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}
/* .pr-title-textarea {
  align-self: stretch;
  flex: 1 1 0;
  padding: 10px;
  border-radius: 5px;
  border: 0.5px #ebebeb solid;
  display: inline-flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
} */
/* .pr-title-textarea textarea {
  resize: none;
  color: #999;
  font-family: Sarabun;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
} */
.pr-note-textarea {
  width: 75%;
  align-self: stretch;
  flex: 1 1 0;
  border-radius: 5px;
  border: 0.5px #ebebeb solid;
  display: inline-flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
}
.pr-note-textarea textarea {
  resize: none;
  color: #999;
  font-family: Sarabun;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}
/* .pr-input input[type="text"] {
  color: #999999;
  font-size: 14px;
  font-family: Sarabun;
  font-weight: 400;
  text-transform: capitalize;
  word-wrap: break-word;
} */

/* array cards */

/* Main container */
.quo-pr-comb-det {
  width: 100%;
  height: 100%;
  padding: 20px;
  background: #fafbff;
  border: 0.5px #ebebeb solid;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
}

/* quo-pr-com-det-text */
.quo-pr-com-det-text {
  color: #173a5a;
  font-size: 18px;
  font-family: "Sarabun";
  font-weight: 400;
  text-transform: capitalize;
  word-wrap: break-word;
}

.quo-pr-com-card-qtn {
  height: 370px;
  width: 94%;
  padding: 10px;
  background: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: 0.5px #999999 solid;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  display: flex;
}

/* Card item container */
.quo-pr-com-card-item {
  width: 100%;
  height: 100%;
  padding: 10px;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
}

/* Card title */
.quo-pr-com-card-title {
  color: #173a5a;
  font-size: 16px;
  font-family: "Sarabun";
  font-weight: 400;
  text-transform: capitalize;
  word-wrap: break-word;
}

/* Card content - input field */
.quo-pr-com-card-content[type="text"] {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 0.5px #ebebeb solid;
  color: #999999;
  font-size: 14px;
  font-family: "Sarabun";
  font-weight: 400;
  text-transform: capitalize;
  word-wrap: break-word;
}

/* Card content - textarea field */
.quo-pr-com-card-content[type="textarea"] {
  width: 100%;
  height: 100px;
  padding: 10px;
  border: 0.5px #ebebeb solid;
  color: #999999;
  font-size: 14px;
  font-family: "Sarabun";
  font-weight: 400;
  word-wrap: break-word;
}
textarea.quo-pr-com-card-content {
  height: 32%;
  resize: none;
  border: 1px solid #ebebeb;
  display: flex;
  padding: 10px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  color: #999;
  font-family: Sarabun;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

/* scrollBar css*/
.qt-main-scroll {
  max-height: 500px;
  overflow-y: auto;
}

.qt-main-scroll::-webkit-scrollbar {
  width: 15px;
  /* height: 100px; */
  flex-shrink: 0;
}

.qt-main-scroll::-webkit-scrollbar-track {
  background: #d9d9d9;
  border: 1px solid #999999;
  border-radius: 20px;
}

.qt-main-scroll::-webkit-scrollbar-thumb {
  width: 21.408px;
  height: 1px;
  flex-shrink: 0;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  background: #103a5d;
  border: 1px solid #999999;
  border-radius: 20px;
}

/* Quotation File */

.qtn-file-container {
  width: 100%;
  height: 100%;
  max-height: 300px;
  overflow-x: auto;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 30px;
  display: inline-flex;
}

.qtn-file-heading {
  align-self: stretch;
  border-bottom: 0.5px #adb2b9 solid;
  justify-content: flex-start;
  align-items: center;
  display: inline-flex;
}

.qtn-file-heading-content {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 20px;
  border-bottom: 1.5px #103a5d solid;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  display: flex;
}

.qtn-file-heading-text {
  text-align: center;
  color: #103a5d;
  font-size: 18px;
  font-family: "Sarabun";
  font-weight: 500;
  word-wrap: break-word;
}

.qtn-file-item-container {
  align-self: stretch;
  height: 135px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 15px;
  display: flex;
}

.qtn-file-item {
  align-self: stretch;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  background: #fafbff;
  border: 0.5px #ebebeb solid;
  justify-content: space-between;
  align-items: center;
  display: inline-flex;
}

.qtn-file-item-content {
  justify-content: center;
  align-items: center;
  gap: 30px;
  display: flex;
}

.qtn-file-item-icon {
  cursor: pointer;
}
.qtn-file-item-text {
  text-align: center;
  color: #103a5d;
  font-size: 18px;
  font-family: "Sarabun";
  font-weight: 400;
  word-wrap: break-word;
}
