.time-stamp-modal {
    width: auto;
    top: 50%;
    min-width: 400px
  }
  
  .ts-time-div {
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    text-transform: capitalize;
    color: #999999;
    white-space: nowrap;
    padding-bottom: 50px;
    padding-top: 10px;
  }
  
  
  .ts-title-close-icon-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .ts-title {
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
    text-transform: capitalize;
    color: var(--main-color);
  }
  
  .ts-status {
    font-weight: 700;
    font-size: 24px;
    line-height: 31px;
    text-transform: capitalize;
    color: var(--main-color);
  }
  
  .ts-main-div {
    display: flex;
    /* justify-content: center; */
    align-items: baseline;
    gap: 30px;
  }
  
  .ts-vl {
    border-left: 1.5px solid #ebebeb;
    height: 30px;
    margin-left: 20px;
    padding-right: 20px;
  }
  
  .ts-img-container {
    position: relative;
  }
  
  .ts-vertical-line {
    position: absolute;
    width: 5px;
    height: 100%;
    min-height: 100px;
    background: #d9d9d9;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .ts-date {
    padding-left: 10px;
  }
  
  .ts-person-icon{
    padding-right: 10px;
  }
  
  .changelog-box{
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 8px;
  }
  .changelog-date-heading{
    text-align: center;
  }

  .changelog-innerboxes {
    display: grid;
    grid-template-columns: 1.5fr 1.5fr 0.5fr 2fr;
    gap: 10px;
    width: 100%;
}

.changelog {
  border-radius: 5px;
  border: 1px solid #EBEBEB;
  padding: 10px;
  display: flex;
  flex-direction: column;
  min-height: fit-content;
  overflow: hidden;
}
.changelog div {
  margin-bottom: 5px;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
}
  .changelog-title{
    color: rgba(16, 58, 93, 1);
  }
  .changelog-value{
    color: rgba(153, 153, 153, 1);
  }
  
.changelog-arrows {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #999999;
}
