.pagination-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: 10px;
  }
  
  .pagination-container button {
    border: 1px solid #ccc;
    padding: 5px 10px;
    background-color: white;
    color: var(--main-color);
    font-family: 'Noto Sans SC';
    font-weight: 400;

  }
  
  .pagination-container button.active {
    background-color: var(--main-color);
    color: white;
  }
  