.sm-table-search-div {
    height: 48px;
    background: #ffffff;
    border: 1px solid #999999;
  }
  
  .sm-table-search-div input {
    height: 38px;
    border: 1px solid #999999;
    padding: 5px;
    width: 13%;
    margin-right: 10px;
  }

/* status */
.delivered {
  background: #e0f8f2;
  color: #43d5ae;
}

.delayed {
  background: #fff3f1;
  color: #fd8a75;
}

.delivering {
  color: #eac52d;
  background: #fcf6dd;
}
.penalty-reject {
  color: #428bc1;
  background: #dfe6f1;
}
.penalty-confirm{
  color: #09ABE2;
  background: #dfe6f1;
}


/* table btn */

.eva-tbl-btn {
  width: 90px;
  height: 28px;
  color: #ffffff;
  font-weight: 400;
  font-size: 14px;
  margin-right: 8px;
  font-family: "Sarabun";
  background:#428bc1;
  border-radius: 5px;
}

.pen-tbl-btn {
  width: 90px;
  height: 28px;
  color: #ffffff;
  font-weight: 400;
  font-size: 14px;
  font-family: "Sarabun";
  background:#ED4C5C;
  border-radius: 5px;
}

.vpr-tbl-btn {
  width: 139px;
  height: 28px;
  color: #ffffff;
  font-weight: 400;
  font-size: 14px;
  font-family: "Sarabun";
  background:#FFCE31;
  border-radius: 5px;
}
.dp-tbl-btn {
  width: 139px;
  height: 28px;
  color: #ffffff;
  font-weight: 400;
  font-size: 14px;
  font-family: "Sarabun";
  background:#09ABE2;
  border-radius: 5px;
}

.pointer-none{
  pointer-events: none;
  opacity: 0.6;
  cursor: not-allowed;
}