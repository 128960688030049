

.cv-navbar {
  text-decoration: none !important;
  border-bottom: 1px solid #adb2b9 !important;
  
}


.create-vendor-nav-active {
  text-decoration: none !important;
  border-bottom: 2px solid var(--main-color) !important;
}

.create-vendor-nav {
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: capitalize;
  color: rgba(146, 146, 146, 0.952);
  text-decoration: none !important;
}
.create-vendor-text-active {
  color: #0b1d35;
  text-decoration: none !important;
}

.create-vendor-text {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 21px;
  color: #adb2b9;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: capitalize;
  text-decoration: none !important;
}

.nav-link{
  padding: 4px 14px 11px !important;
}

.rmsc .dropdown-container{
  height: 31px !important;
  border: 0px !important;
}

/* Change the date picker arrow icon for Webkit browsers */
input[type="date"]::-webkit-calendar-picker-indicator {
  background: url("../../Assets/Svg/calender-icon.svg") no-repeat;
  background-size: contain;
  width: 20px; /* Adjust the width as needed */
  height: 20px; /* Adjust the height as needed */
  opacity: 1; /* Ensure the icon is fully visible */
  cursor: pointer;
  margin-left: 5px;
}

.rmsc .select-item.selected{
  background-color: white !important;
  color:#000000 !important
}