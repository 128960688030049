.evaluated-info-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
}
.table-btn {
  width: 90px;
  height: 28px;
  color: #ffffff;
  font-weight: 400;
  font-size: 14px;
  font-family: "Sarabun";
  background: var(--main-color);
  border-radius: 5px;
}
.evaluated-info-box {
  flex: 1 1 0;
  height: 46px;
  padding: 10px;
  background: #ebebeb;
  border-radius: 5px;
  border: 0.5px #adb2b9 solid;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
}

.evaluated-info-text {
  text-align: center;
  color: #999999;
  font-size: 20px;
  font-family: Sarabun;
  font-weight: 400;
  word-wrap: break-word;
}

.evaluated-vendor-title {
  width: 100%;
  border-bottom: 0.5px #adb2b9 solid;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.evaluated-vendor-title-text {
  padding: 10px;
  padding-right: 20px;
  border-bottom: 1.5px #103a5d solid;
  color: #103a5d;
  font-size: 20px;
  font-family: Sarabun;
  font-weight: 700;
  word-wrap: break-word;
}

.evaluated-vendor-info-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.evaluated-vendor-info {
  width: 100%;
  padding: 10px 0;
  border-bottom: 0.5px #adb2b9 solid;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 500px;
}

.evaluated-vendor-label {
  color: #103a5d;
  font-size: 18px;
  font-family: Sarabun;
  font-weight: 400;
  word-wrap: break-word;
}

.evaluated-vendor-value {
  color: black;
  font-size: 18px;
  font-family: Sarabun;
  font-weight: 400;
  text-transform: capitalize;
  word-wrap: break-word;
}

/* table css */

.evaluated-table {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid #ddd;
}

.evaluated-table-row {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #000;
  color: #103a5d;
}

.eva-tbl-header-row {
  display: flex;
  align-items: flex-start;
  background-color: #103a5d;
  color: #ffffff;
}

.evaluated-table-header-cell,
.evaluated-table-cell {
  padding: 8px;
  text-align: center;
  border: 1px solid #3a6487;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.special-cell {
  color: #103a5d;
  font-family: Sarabun;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background-color: #dfe6f1;
}

.eva-table-checkbox[type="checkbox"] {
  width: 20px;
  height: 20px;
  border: 2px solid #103a5d;
  appearance: none;
  accent-color: #afca0b;
  cursor: pointer;
  position: relative; /* Added for positioning the checkmark */
}

.eva-table-checkbox[type="checkbox"]:checked {
  background-color: #afca0b;
  border: none;
}

.eva-table-checkbox[type="checkbox"]:checked:disabled {
  background-color: #afca0b !important;
}
.eva-table-checkbox[type="checkbox"]:checked::before {
  content: "\2713"; /* Unicode checkmark character */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Center the checkmark */
  font-size: 14px; /* Adjust the size of the checkmark */
  color: white; /* Color of the checkmark */
}

.eva-tbl-header-textt {
  color: #fff;
  font-family: Sarabun;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

/* Apply text-align: start; to "Evaluation" column cells */
.evaluated-table-cell:nth-child(2),
.evaluated-table-header-cell:nth-child(2) {
  text-align: start;
}

.eva-tbl-total-row {
  color: #fff;
  text-align: right;
  font-family: Sarabun;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

/* small table */
.EvaSmall-table-container {
  width: 43%;
}

.eva-table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ccc;
}

.eva-table th,
.eva-table td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: center;
  color: #103a5d;
  font-family: Sarabun;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  background: #fff;
}

.eva-table th {
  background-color: #103a5d;
  color: #fff;
  font-family: Sarabun;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

/*Supplier table  */
.eva-supplier-top-container{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.Criteria{
  
  color: #103a5d;
  font-weight: bold;
  font-size: 1.5rem;
}

.remark{
  color: #103a5d;
}
.remark span{
  font-weight: bold;
  text-decoration: underline;
}

/* small table */


.EvaSmall-table-container-supplier {
  width: 70%;
}

.eva-table-supplier {
  width: 100%;
  border-collapse: collapse;
  border: none;
}


.eva-table-supplier td {
  border: none;
  border-right: none !important;
  padding: 8px;
  text-align: center;
  color: #103a5d;
  font-family: Sarabun;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  background: #fff;
}



/* Note css */
.eva-note-container {
  width: 100%;
  height: 100%;
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
}

.eva-note-title {
  color: #103a5d;
  font-size: 28px;
  font-family: "Sarabun";
  font-weight: 700;
  text-decoration: underline;
  text-transform: capitalize;
  word-wrap: break-word;
}

.eva-note-content {
  width: 100%;
  color: #103a5d;
  font-size: 20px;
  font-family: "Sarabun";
  font-weight: 400;
  line-height: 33px;
  word-wrap: break-word;
}


/* making the table disabled by default */
.disabled-table {
  opacity: 0.5; 
  pointer-events: none; 
}

.disabled-table .eva-table-checkbox {
  pointer-events: none; 
}


.eva-read-only {
  pointer-events: none; 
  opacity: 0.95; 
}

.checkbox-selection{
  color: #103a5d;
}
.input-radio{
  padding-right: 1rem;
}
.inputTextbox{
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: #103a5d;
}

.textArea-selected{
  border: 1px solid #103a5d;
  border-radius: 8px;
  padding: 2px;
}

.evaText{
  color: #103a5d;
  padding: 8px 0;
}

.disabled-table {
  pointer-events: none; 
  opacity: 0.6; 
}