.admin-acc-title-btn {
    padding-top: 50px;
    display: flex;
    align-items: center;
    padding-bottom: 38px;
    gap: 15px;
}

.admin-acc-txt {
  color: #103a5d;
  font-family: Sarabun;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}

.admin-acc-main label {
  color: #103a5d;
  font-family: Sarabun;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  margin-bottom: 13px;
}

.admin-acc-main input {
  display: flex;
  width: 100%;
  height: 38px;
  padding: 10px;
  align-items: flex-start;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 5px;
  border: 1px solid #adb2b9;
}

.admin-eml-ph {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
  width: 100%;
}

.admin-eml-ph label {
  color: #103a5d;
  font-family: Sarabun;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  margin-bottom: 13px;
}

.admin-eml-ph input {
  width: 100%;
  padding: 10px;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid #adb2b9;
}




.error-message {
    color: #ff0000; 
    font-size: 14px; 
    margin-top: 4px; 
  }


.admin-acc-main{
  width: 50%;
}




