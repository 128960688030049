.pr-release {
  color: #103a5d;
  font-size: 28px;
  font-family: Sarabun;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  padding: 20px;
}

.pr-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.pr-card {
  display: flex;
  width: 220px;
  padding: 20px 30px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 5px;
  border: 1px solid #ebebeb;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.pr-title {
  color: #4f4f4f;
  font-size: 18px;
  font-family: Sarabun;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}

.pr-content{
  display: grid;
  grid-template-columns: repeat(2, 1fr); 
  min-width: 200px;
}

.pr-content p {
  color: #4f4f4f;
  font-size: 18px;
  font-family: Sarabun;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  margin: 5px 20px 5px 0px;
}


