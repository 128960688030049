.um-main {
  padding-top: 80px;
  padding-right: 20px;
  margin-left: 300px;
}
.um-not-main{
  padding-top: 20px;
  padding-right: 20px;
  margin-left: 30px;
}

.um-title-btn {
  padding-top: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  
}

.um-title {
  font-weight: 700;
  font-size: 25px;
  line-height: 36px;
  text-transform: capitalize;
  color: var(--main-color);
}

.um-tile-not-transform{
  text-transform: none !important;
}

.create-btn {
  width: 220px !important;
  height: 43px !important;
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 700 !important;
  font-size: 18px !important;
}

.um-text {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: var(--main-color);
  padding-bottom: 20px;
  padding-top: 10px;
}

.um-chart {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 30px;
}