.create-purchasing-btn{
    width: 287px !important;
    height: 43px !important;
    font-family: "Sarabun";
    font-style: normal;
    font-weight: 700 !important;
    font-size: 18px !important;
    color: #ffffff;
    background: var(--main-color);
    border-radius: 5px;
}