.pr-release-cre-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 71vw;
  height: auto;
  background-color: white;
  z-index: 9999;
  overflow: auto;
  outline: none;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.date-main-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 2%;
  margin-bottom: 17px;
}

.pr-rel-code-select {
  width: 100%;
  display: inline-block;
}

.pr-rel-select-container {
  position: relative;
}

.pr-code-select {
  width: 40vw;
  height: 43px;
  display: flex;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 5px;
  border: 1px solid #adb2b9;
  background: #fff;
}

/* pr-release-filter-popup.css */

/* Style for each MultiSelect box */
.pr-release-multiselect {
  width: 160px;
  white-space: wrap;
}
.pr-release-multiselect .multi-select__option {
  display: block; 
  width: 100%; 
  white-space: normal;
  word-wrap: break-word; 
}



/* Apply button */
.pr-release-apply-button {
  display: flex;
  padding: 5px 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 5px;
  background: #103a5d;
  color: white;
  cursor: pointer;
}

/* Modal */
.pr-release-filter-modal {
  /* position: relative;
  width: fit-content;
  margin: -421px auto auto 319px;
  background-color: #ebebeb;
  overflow: unset;
  outline: none;
  padding: 12px;
  z-index: 9999; */
  position: absolute;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: 1090px;
  margin-left: 300px;

  /* margin: 807px auto auto 319px; */
  background-color: #ebebeb;
  overflow: unset;
  outline: none;
  padding: 12px;
  z-index: 9999;
}

/* Dropdown heading style */
.pr-release-multiselect .dropdown-heading {
  height: 32px;
}

.pr-rele-multiselect-row {
  display: flex;
  justify-content: flex-start;
  gap: 5px;
  align-items: center;
  width: 100%;
}

.pr-release-multiselect .options {
  color: #9f9c9c;
  background-color: #f4f4f4;
}

.pr-release-multiselect [type="checkbox"] {
  width: 15px;
  height: 15px;
  min-width: 15px; 
  border: 1px solid #999999;
  appearance: none;
  accent-color: #103a5d;
  cursor: pointer;
  position: relative; /* Added for positioning the checkmark */
}

.pr-release-multiselect [type="checkbox"]:checked {
  background-color: #103a5d;
  border: none;
}

.pr-release-multiselect [type="checkbox"]:checked::before {
  content: "\2713"; /* Unicode checkmark character */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Center the checkmark */
  font-size: 14px; /* Adjust the size of the checkmark */
  color: white; /* Color of the checkmark */
}

.pr-rele-labels label {
  color: #103a5d;
  font-family: Sarabun;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 10px;
}
.pr-bid-end-inp {
  display: flex;
  padding: 9px;
  align-items: center;
  gap: 61px;
  border-radius: 5px;
  border: 1px solid #adb2b9;
  background: #fff;
}

.pr-rel-inp-tit-text input {
  display: flex;
  width: 100%;
  padding: 10px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid #adb2b9;
}

@media (min-width: 1356px) {
  .pr-release-filter-modal {
    margin-top: 1090px;
  }
}

@media (min-width: 1750px) {
  .pr-release-filter-modal {
    margin-top: 690px;
  }
}
