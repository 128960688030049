.table-search-div {
  height: 48px;
  background: #ffffff;
  border: 1px solid #999999;
}

.table-search-div input {
  height: 38px;
  border: 1px solid #999999;
  padding: 5px;
  width: 13%;
  margin-right: 10px;
}

.table-btn {
  width: 90px;
  height: 28px;
  color: #ffffff;
  font-weight: 400;
  font-size: 14px;
  font-family: "Sarabun";
  background: var(--main-color);
  border-radius: 5px;
}

.user-management-table .rdt_TableRow {
  border-bottom: 0px !important;
}

.um-table-head {
  height: 140px;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  background: #fafbff;
}

.um-all {
  border-bottom: 2px solid;
  border-image: linear-gradient(to right, #103a5d 5%, #d9d9d9 35%, #d9d9d9 85%)
    2;
}

.um-all p {
  padding-top: 20px;
  padding-bottom: 20px;
  text-transform: capitalize;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  color: var(--main-color);
}

.um-entries-div {
  padding-top: 20px;
  padding-bottom: 20px;
}

.um-entries p {
  font-size: 14px;
  line-height: 18px;
  text-transform: capitalize;
  color: var(--main-color);
  padding-right: 10px;
}

.um-entries select {
  width: 50px;
  height: 22px;
  background: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 2px;
  font-size: 15px !important;
}

.um-entries span {
  padding-left: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-transform: lowercase;
  color: #979797;
}

.um-all p > span {
  width: 39px;
  height: 28px;
  padding: 5px;
  background: var(--main-color);
  color: #ffffff;
  font-weight: 700;
  font-size: 14px;
}

.um-table-header-div {
  border: 1px solid #ebebeb;
}

.um-show-entries {
  font-weight: 400;
  font-size: 14px;
  color: #999999;
}

.um-empty-data-table .rdt_Table {
  margin-bottom: 70px !important;
}
.um-data-table .rdt_TableHead {
  margin-bottom: 50px !important;
}

.vm-empty-data-table .rdt_Table {
  margin-bottom: 70px !important;
}
.vm-data-table .rdt_TableHead {
  margin-bottom: 50px !important;
}

.um-data-table div::-webkit-scrollbar {
  display: none;
}

.rdt_TableHeadRow div {
  background-color: #103a5d;
}

.jbm-file-icon {
  padding: 5px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background: #103a5d;
}

.jbm-pen-icon {
  padding: 5px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background: #428bc1;
}

.jbm-delivered-btn {
  width: 118px;
  padding: 5px 20px;
  border-radius: 5px;
  background: #e0f8f2;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: #43d5ae;
  text-align: center;
  font-family: Sarabun;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}


.rdt_TableCell{
  text-transform: none;
}

.rdt_TableCell div {
  white-space: unset !important;
}