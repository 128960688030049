.short-text-inp {
  display: flex;
  width: 1100px;
  padding: 10px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid #ebebeb;
}

.long-text-txt {
  display: flex;
  width: 100%;
  height: 100px;
  padding: 10px;
  align-items: flex-start;
  gap: 10px;
  border: 1px solid #ebebeb;
}
