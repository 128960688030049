.next-approver-dropdown {
  margin-top: 20px;
  margin-bottom: 20px;
  height: 43px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #adb2b9;
  border-radius: 5px;
  padding: 5px;
  color: black;
}
