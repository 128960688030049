.top-bar-main {
  position: fixed;
  width: 100%;
  height: 82px;
  /* left: 220px; */
  top: 0px;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 1000;
}

.top-bar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.top-bell {
  width: 80px;
  height: 82px;
  border-width: 0px 1px;
  border-style: solid;
  border-color: #ebebeb;
  display: flex;
  justify-content: center;
  align-items: center;
}

.top-bell svg {
  height: 20px !important;
  width: 20px !important;
}

.tb-name-p {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #003595;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 26px;
  color: #ffffff;
  text-transform: capitalize;
  margin-left: 25px;
  margin-right: 10px;
}

.tb-name {
  font-weight: 700;
  font-size: 20px;
  color: var(--main-color);
}

.menu-dropdown {
  position: absolute;
  top: 75px;
  margin-left: 22px;
  width: 217px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  z-index: 5;
}

.menu-dropdown ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu-dropdown li {
  padding: 8px 12px;
  cursor: pointer;
  color: var(--main-color);
}

.menu-dropdown li:hover {
  background-color: #f1f1f1;
}

.menu-dropdown hr {
  height: 1px !important;
  color: #ebebeb;
  margin: 0 0;
  opacity: unset;
}

.flag-dropdown {
  position: absolute;
  top: 75px;
  margin-left: -52px;
  width: 195px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  z-index: 7;
}

.flag-img-div img {
  width: 32px;
  height: 32px;
}

.flag-img-div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 5px;
  gap: 10px;
  cursor: pointer;
}
.flag-img-div:hover {
  background-color: #f1f1f1;
}

.flag-dropdown p {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-transform: capitalize;
  color: var(--main-color);
}
