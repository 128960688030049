.prr-table-search-div {
  height: 48px;
  background: #ffffff;
  border: 1px solid #999999;
}

.prr-table-search-div input {
  height: 38px;
  border: 1px solid #999999;
  padding: 5px;
  width: 13%;
  margin-right: 10px;
}

/* status */

.Deleted {
  background: #FFF3F1;
  color: #FD8A75 !important;
}

.NewPR {
  color: #eac52d !important;
  background: #fcf6dd;
}
.Released {
  color: #FFFFFF !important;
  background: rgba(16, 58, 93, 0.7) !important;
}

/* table btn */

.prr-af-btn {
  width: 83px;
  height: 28px;
  color: #ffffff;
  font-weight: 400;
  font-size: 14px;
  font-family: "Sarabun";
  background: #428bc1;
  border-radius: 5px;
}
.prr-rel-btn {
  width: 70px;
  height: 28px;
  color: #ffffff;
  font-weight: 400;
  font-size: 14px;
  font-family: "Sarabun";
  background: var(--main-color);
  border-radius: 5px;
}
.prr-del-tbl-btn {
  width: 70px;
  height: 28px;
  color: #ffffff;
  font-weight: 400;
  font-size: 14px;
  font-family: "Sarabun";
  background: #ed4c5c;
  border-radius: 5px;
}

.prr-filter-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #103a5d;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #ffffff;
  width: 71px;
  border-radius: 0px;
  margin-left: 10px;
  gap: 3px;
}

.description-cell {
  display: flex;
  flex-wrap: nowrap;
  padding: 5px;
}

.pr-release-table-check [data-aria-label="select-all-rows"] {
  display: none !important;
}

.pr-release-table-check [type="checkbox"] {
  width: 13px;
  height: 13px;
  border: 2px solid #999999;
  appearance: none;
  accent-color: #103a5d;
  cursor: pointer;
  position: relative; /* Added for positioning the checkmark */
}

.pr-release-table-check [type="checkbox"]:checked {
  background-color: #103a5d;
  border: none;
}

.pr-release-table-check [type="checkbox"]:checked::before {
  content: "\2713"; /* Unicode checkmark character */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Center the checkmark */
  font-size: 11px; /* Adjust the size of the checkmark */
  color: white; /* Color of the checkmark */
}


