.close-icon-div {
  display: flex;
  justify-content: flex-end;
}

.popup-title {
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  color: var(--main-color);
}

.popup-text {
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  color: #767676;
}
