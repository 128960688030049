.action-view-table-btn {
  display: flex;
  padding: 5px 10px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-radius: 5px;
  background: #eac52d;
  color: #fff;
  text-align: center;
  font-family: Sarabun;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 71px;
}

.history-supplier-visit-table-active {
  border-bottom: 2px solid var(--main-color);
  transition: all 0.2s ease-in-out;
  font-family: "Sarabun" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: capitalize;
  text-decoration: none !important;
  color: var(--main-color) !important;
}

.history-supplier-visit-table {
  font-family: "Sarabun" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: capitalize;
  color: #adb2b9 !important;
  text-decoration: none !important;
}

.history-supplier-visit-table-badge-active {
  padding: 2px;
  margin-left: 5px;
  background: #103a5d;
  color: white;
  text-decoration: none !important;
}

.history-supplier-visit-table-badge {
  margin-left: 5px;
  padding: 2px;
  margin-left: 5px;
  background: #ebebeb;
  color: #999999;
  text-decoration: none !important;
}

.history-table-navbar {
  padding-top: 20px;
  margin-bottom: 10px;
  border-bottom: 2px solid #ebebeb;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.history-table-status {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  border-radius: 5px;
  padding: 5px 10px;
  text-transform: capitalize;
  white-space: nowrap;
  color: #ffffff;
}


.hs-view-qtn {
  width: 74px;
    height: 22px;
  color: #ffffff;
  font-weight: 400;
  font-size: 10px;
  font-family: "Sarabun";
  background: var(--main-color);
  border-radius: 5px;
}
.hs-download-po {
  color: #fff;
  text-align: center;
  font-family: Sarabun;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background: #eac52d;
}


.hs-view-evaluated {
  color: #fff;
  text-align: center;
  font-family: Sarabun;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background: #43d5ae;
}
.hs-download-penalty {
  color: #fff;
  text-align: center;
  font-family: Sarabun;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background: #ed4c5c;
}



.history-expaneded-table-div {
  background: #ebebeb;
  padding: 25px 5px 5px 5px;
}

.history-expanded-table {
  width: 100%;
}

.history-expanded-table th {
  height: 28px;
  width: 10%;
  background: #999999;
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  text-transform: capitalize;
  color: #ffffff;
}

.history-expanded-table td {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  text-transform: capitalize;
  background-color: white;
  color: #103a5d;
  border-bottom: 1px solid #dee2e6;
  padding-top: 5px;
  padding-bottom: 5px;
}

.history-table button:disabled {
  display: none;
}

/* data table css here */
.history-table-search-div {
  height: 48px;
  background: #ffffff;
  border: 1px solid #999999;
}

.history-table-search-div input {
  height: 38px;
  border: 1px solid #999999;
  padding: 5px;
  width: 13%;
  margin-right: 10px;
}