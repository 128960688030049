.eva-fs-name {
  color: #103a5d;
  font-family: Sarabun;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.evaa-cre-user-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.evaa-cre-user-title h2 {
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;
  text-transform: capitalize;
  color: var(--main-color);
}

.evaa-cre-user-title img {
  cursor: pointer;
}

.eva-user-model {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60vw;
  height: auto;
  background-color: white;
  z-index: 9999;
  overflow: auto;
  outline: none;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.eva-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(153, 153, 153, 0.7);
  backdrop-filter: blur(5px);
  z-index: 9998;
  pointer-events: auto;
}

.eva-modal-overlay.hide {
  pointer-events: none;
}

.create-btns-div {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}

.um-create-btn {
  /* width: 102px;
    height: 28px; */
  font-size: 20px;
  font-weight: 500;
  background: #ffffff;
  border: 1px solid var(--main-color);
  border-radius: 5px;
  padding: 5px 40px;
}

.um-submit-btn {
  /* width: 104px;
    height: 28px; */
  font-size: 20px;
  font-weight: 500;
  color: #ffffff;
  background: var(--main-color);
  border-radius: 5px;
  padding: 5px 40px;
}

.evaa-form-select-dropdown {
  position: relative;
  display: inline-block;
  width: 100%;
}

.evaa-form-select-dropdown select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  height: 43px;
  border: 1px solid #ebebeb;
  border-radius: 5px;
  padding: 5px;
}

.evaa-form-select-dropdown::after {
  content: "\25BC";
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  color: #555;
  pointer-events: none;
}

.evaa-form-select-dropdown select {
  border-radius: 5px;
  border: 1px solid #adb2b9;
  background: #fff;
}

.evaa-error-msg {
  color: red;
  font-size: 1rem;
  margin-top: 5px;
}
