.vi-name {
  padding-top: 20px;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  text-transform: capitalize;
  color: var(--main-color);
}

.vi-content {
  margin-top: 20px;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #adb2b9;
  height: auto;
  width: 100%;
  min-height: 72px;
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  /* text-transform: capitalize; */
  color: var(--main-color);
}
