.create-user-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.create-user-title h2 {
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;
  text-transform: capitalize;
  color: var(--main-color);
}

.create-user-title img {
  cursor: pointer;
}

.create-user-form {
  width: 100%;
}

.create-user-form label {
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  text-transform: capitalize;
  color: var(--main-color);
  padding-top: 10px;
  padding-bottom: 10px;
}

.create-user-form input {
  width: 100%;
  height: 43px;
  border: 1px solid #ebebeb;
  border-radius: 5px;
  padding: 5px;
}

.create-user-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60vw;
  height: auto;
  background-color: white;
  z-index: 9999;
  overflow: auto;
  outline: none;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.create-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(153, 153, 153, 0.7);
  backdrop-filter: blur(5px);
  z-index: 9998;
  pointer-events: auto;
}

.create-modal-overlay.hide {
  pointer-events: none;
}

.create-btns-div {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}

.um-create-btn {
  /* width: 102px;
  height: 28px; */
  font-size: 20px;
  font-weight: 500;
  background: #ffffff;
  border: 1px solid var(--main-color);
  border-radius: 5px;
  padding: 5px 40px;
}

.um-submit-btn {
  /* width: 104px;
  height: 28px; */
  font-size: 20px;
  font-weight: 500;
  color: #ffffff;
  background: var(--main-color);
  border-radius: 5px;
  padding: 5px 40px;

}

.group-dropdown {
  position: relative;
  display: inline-block;
  width: 100%;
}

.group-dropdown select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  height: 43px;
  border: 1px solid #ebebeb;
  border-radius: 5px;
  padding: 5px;
}

.group-dropdown::after {
  content: "\25BC";
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  color: #555;
  pointer-events: none;
}

.group-dropdown select {
  color: var(--main-color);
}

.user-edit-btn {
  width: 74px;
  height: 31px;
  color: #ffffff;
  background: var(--main-color);
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
