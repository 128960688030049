.pr-details-user-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 74vw;
  height: 100%;
  background-color: white;
  z-index: 9999;
  overflow: auto;
  outline: none;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.pr-details-pr-title {
  width: 50%;
}

.pr-details-pr-title label {
  color: #103a5d;
  font-family: Sarabun;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  margin-bottom: 13px;
}

.pr-details-pr-title input {
  display: flex;
  width: 100%;
  height: 38px;
  padding: 10px;
  align-items: flex-start;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 5px;
  border: 1px solid #adb2b9;
}

.pr-details-date-main {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
  width: 73%;
}

.pr-details-date-main label {
  color: #103a5d;
  font-family: Sarabun;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  margin-bottom: 13px;
}

.pr-details-date-main input {
  width: 100%;
  padding: 10px;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid #adb2b9;
}

.pr-details-bidd-deli-text {
  width: 30%;
}

/* QuotationDetailsPopup */

.quo-details-user-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 74vw;
  height: 100%;
  background-color: white;
  z-index: 9999;
  overflow: auto;
  outline: none;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.quo-details-date-main {
  display: flex;
  align-items: flex-start;
  gap: 30px;
  align-self: stretch;
}

.quo-details-date-main label {
  color: #103a5d;
  font-family: Sarabun;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  margin-bottom: 13px;
}

.quo-details-date-main input[type="date"] {
  display: flex;
  padding: 8px;
  align-items: center;
  gap: 120px;
  border-radius: 5px;
  border: 1px solid #adb2b9;
  background: #fff;
}

.quo-details-date-main input[type="text"] {
  display: flex;
  padding: 10px;
  align-items: center;
  gap: 120px;
  border-radius: 5px;
  border: 1px solid #adb2b9;
  background: #fff;
}

.quo-chat-logo {
  display: flex;
  margin-top: 38px;
  padding: 8px;
  align-items: center;
  gap: 10px;
  color: white;
  border-radius: 5px;
  background: #103a5d;
}

.quo-long-text {
  width: 100%;
  height: 100px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #EBEBEB;
  resize: none;
  color: #999;
  font-family: Sarabun;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}


