/* status */
.evaluated {
    background: #e0f8f2;
    color: #43d5ae;
  }
  
  .delete-evaluation {
    background: #fff3f1;
    color: #fd8a75;
  }
  
  .waiting-evaluation {
    color: #eac52d;
    background: #fcf6dd;
  }
  


/* table btn */

.eva-edit-tbl-btn {
    width: 34px;
    height: 28px;
    color: #ffffff;
    font-weight: 400;
    font-size: 14px;
    font-family: "Sarabun";
    background:#09ABE2;
    border-radius: 5px;
  }

  .eva-dele-tbl-btn {
    width: 34px;
    height: 28px;
    color: #ffffff;
    font-weight: 400;
    font-size: 14px;
    font-family: "Sarabun";
    background:#ED4C5C;
    border-radius: 5px;
  }