/* .dropdown-heading svg {
    background-image: url("../../../Assets/Svg/drop-down-icon.svg");
  } */

  .rmsc {
    padding-left: 10px;
    padding-right: 10px;
}

.rmsc .dropdown-heading {
  height: 41px;
}
.rmsc .dropdown-container:focus-within{
  box-shadow: none !important;
}

.dropdown-multi .dropdown::after {
  content: url("../../../Assets/Svg/drop-down-icon.svg");
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  color: #555;
  pointer-events: none;
  /* background: url("../../../Assets/Svg/drop-down-icon.svg"); */
}

.sub-category-drop .actions-btn {
  display: none !important;
}

.sub-category-drop {
  border: unset !important;
}
.sub-category-drop .dropdown .btn-light {
  border: 1px solid #adb2b9;
  border-radius: 10px;
  height: 40px !important;
}
.sub-category-drop .dropdown .dropdown-toggle::after {
  content: url("../../../Assets/Svg/drop-down-icon.svg") !important;
  border-top: 0px;
  border-left: 0px;
}
