.jbm-card {
  padding: 20px 15px;
  width: auto;
}

.jbm-card-title {
  color: #4f4f4f;
  font-weight: 700;
  text-transform: capitalize;
  line-height: normal;
}

.jbm-card-content {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
}

.jbm-card-value {
  display: flex;
  justify-content: center;
  align-items: center;
}

.jbm-card-key {
  color: var(--main-color);
  text-transform: capitalize;
  font-family: Sarabun;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.jbm-card-value {
  margin-top: 10px;
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
  font-family: Sarabun;

}
