.create-send-po-modal {
  height: 80% !important;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.snd-po-main-div {
  display: flex;
  width: 100%;
  padding: 20px 30px;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  border: 1px solid #ebebeb;
  background: #fafbff;
}

.snd-po-text-icon {
  display: flex;
  justify-content: space-around;
  align-items: center;
  align-self: stretch;
  margin-bottom: -2%;
}
.snd-po-text-icon label {
  color: #103a5d;
  font-family: Sarabun;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.snd-po-dropzone {
  width: 62%;
  height: 189px;
  flex-shrink: 0;
  border: 2px dashed #999;
  background: #fff;
  cursor: pointer;
}
.snd-po-sele-file-btn {
  display: flex;
  padding: 5px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  background: #103a5d;
  color: #fff;
}
.snd-po-dropzone-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* gap: 10px; */
  margin-top: 5%;
}

.snd-po-document-file {
  display: flex;
  align-items: center;
  align-self: stretch;
  border-bottom: 1px solid #adb2b9;
}
.snd-po-document-file label {
  border-bottom: 3px solid #103a5d;
}

.snd-po-file-border {
  border: 1px solid #ebebeb;
  background: #fafbff;
}

.snd-po-upload-file {
  display: flex;
  padding: 10px 20px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.send-po-error-m {
  color: red;
  font-size: 10px;
}
.sendpo-remove-logo {
  max-width: 21px;
  height: auto;
  cursor: pointer;
}
.sendpo-pdf-logo{
  max-width: 23px;
  height: auto;
}
.send-po-close-icon{
  cursor: pointer;
}