.create-penalty-rej-modal {
    height: 64% !important;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer and Edge */
  }
  
  .penalty-rej-title {
    width: 1100px;
    border-radius: 5px;
    border: 1px solid #ebebeb;
  }
  .penalty-rej-description {
    display: flex;
    gap: 10px;
    width: 100%;
    height: auto;
    min-height: 100px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ebebeb;
    resize: none;
  }
  
  .prej-attachment-files {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .prej-attach-logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 4%;
  }
  
  .prej-doc-logo {
    border: 1px solid #ebebeb;
    background: #fdfdff;
    display: flex;
    padding: 5px 30px;
    align-items: center;
    gap: 10px;
  }
  
  .prej-doc-png {
    border: 1px solid #ebebeb;
    background: #fdfdff;
    display: flex;
    padding: 5px 30px;
    align-items: center;
    gap: 10px;
  }
  