.pc6a-extension {
    font-family: "Sarabun";
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    text-transform: capitalize;
    color: #103a5d;
    padding-right: 12px;
  }
  
  .po-details-top-cards-content {
    font-family: "Sarabun";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    text-transform: capitalize;
    color: #103a5d;
    word-wrap: break-word;
    white-space: normal;
    line-height:20px;
  }
  
  .po-details-top-cards-header {
    font-family: "Sarabun";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: #103a5d;
  }
  
  .item-1 {
    grid-row: 1/5;
    border-right: 1px solid #ebebeb;
  }
  
  .item-2 {
    grid-row: 1/5;
    border-right: 1px solid #ebebeb;
  }
  
  .item-5 {
    grid-column: 3/5;
  }
  
  .item-3 {
    border-right: 1px solid #ebebeb;
    border-bottom: 1px solid #ebebeb;
  }
  
  .item-4 {
    border-bottom: 1px solid #ebebeb;
  }
  
  .grid-container {
    display: grid;
    border: 1px solid #ebebeb;
    background-color: #fff;
  }
  .grid-container-print {
    display: grid;
    border:1px solid #0b0b0b;
  }
  .grid-container-print-2 {
    display: grid;
    border-right:1px solid #0b0b0b;
    border-left:1px solid #0b0b0b;
    border-bottom: 1px solid #0b0b0b;
  }
  .item-1-print{
    grid-row: 1/5;
    border-right: 1px solid #0b0b0b;
  }

.item-3-print {
    border-right: 1px solid #0b0b0b;
    border-bottom: 1px solid #0b0b0b;
  }
  
  .item-4-print {
    border-bottom: 1px solid #0b0b0b;
  }
  .item1-print {
    grid-column: 1;
    border-right: 1px solid #0b0b0b;
  }
  .item1-1-print {
    grid-column: 1;
    grid-row: 1/3;
    border-right: 1px solid #0b0b0b;
  }
  
  .item2-2-print {
    grid-column: 2/5;
    border-bottom: 1px solid #0b0b0b;
  }
  
  .item3-3-print {
    grid-column: 2/5;
  }

  .po-details-ref-print {
    border-right:1px solid #0b0b0b;
    border-left:1px solid #0b0b0b;
    border-bottom: 1px solid #0b0b0b;
  }

  .table-cell{
    border: 1px solid #0b0b0b !important;
  }
.reference-box{
  display: flex;
  justify-content: space-between;
}
.reference-component{
  border: 1px solid #0b0b0b !important;
}
.pr-details-1-print {
  max-width: 10%;
  border-right: 1px solid #0b0b0b !important;
}
.top-amount-box-print{
  display: block;
  border: 1px solid #0b0b0b !important;
}
.purchase-order{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
  .item1 {
    grid-column: 1;
    border-right: 1px solid #ebebeb;
  }
  
  .item2 {
    grid-column: 2/5;
  }
  
  .item1-1 {
    grid-column: 1;
    grid-row: 1/3;
    border-right: 1px solid #ebebeb;
  }
  
  .item2-2 {
    grid-column: 2/5;
    border-bottom: 1px solid #ebebeb;
  }
  
  .item3-3 {
    grid-column: 2/5;
  }
  
  table {
    border: 1px solid #ebebeb;
  }
  
  th {
    border-bottom: 1px solid #ebebeb !important;
    border-right: 1px solid #ebebeb !important;
  }
  
  td {
    border-right: 1px solid #ebebeb !important;
  }
  
  .po-details-ref {
    border: 1px solid #ebebeb;
    background-color: #fff;
  }
  
  .po-details-top-card {
    border: 1px solid #ebebeb;
    background-color: #fff;
    min-height: 200px;
    height: fit-content;
  }
  
  .po-details-main-page {
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    background: #fafbff;
  }
  
  .po-details-desc {
    font-family: "Sarabun";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    text-transform: capitalize;
    color: rgba(79, 79, 79, 0.8);
  }
  @page {
    size: A4 portrait;
    margin: 0;
  }
/*   
  @media print {
    body * {
      visibility: hidden;
      margin: 0 !important;
      padding: 0 !important;
    }
    .sb-main {
      display: none;
      visibility: hidden;
    }

    

    .po-details-main-page, .po-details-main-page * {
      visibility: visible;
      width: 100%;
      top: 0 !important;
      left: 0 !important;
    }
  
    .po-details-main-page {
      position: static;
      width: 100%;
      margin: 0;
      padding: 0;
    }
  
    .po-details-main-page {
      padding: 20px !important;
      margin: 0 !important;
    }
    .po-details-main-page {
      position: static;
      width: 100%;
      margin: 0;
      padding: 0;
    }
  
    .pc6a-extension {
      display: none;
    }
  
    .logoss {
      display: flex !important;
      flex-direction: row !important;
      align-items: center;
    }
  
    .logo-1 {
      height: 150px !important;
      width: 180px !important;
    }
  
    .logo-2{
      height: 100px !important;
      width: 400px !important;
    }
    .po-details-main-page .d-flex {
      display: flex;
      flex-wrap: wrap;
    }
  
    
    .po-details-main-page .d-flex > div {
      flex: 1;
      margin: 0;
      padding: 0;
    }
  
    table {
      width: 100%;
      border-collapse: collapse;
      table-layout: fixed;
    }
  
    table th, table td {
      padding: 8px;
      text-align: left;
    }
  
    table th:nth-child(1) {
      width: 20%;
    }
  
    table th:nth-child(2) {
      width: 20%;
    }
  
    table th:nth-child(3) {
      width: 20%;
    }
  
    table th:nth-child(4) {
      width: 20%;
    }
    table th:nth-child(5) {
      width: 20%;
    }
  
    button {
      display: none;
    }
    .po-details-desc {
      font-family: "Sarabun";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      text-transform: capitalize;
      color: rgba(79, 79, 79, 0.8);
      margin: 0;
      padding: 0.5rem 0;
    }
    .pr-details {
      display: flex;
      width: 100%;
    }
  
    .pr-details-1 {
      flex: 0 0 10%;
      max-width: 10%;
    }
  
    .pr-details-2 {
      flex: 0 0 90%;
      max-width: 90%;
    }
    
    
      .po-details-main-page.my-4 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important;
      }
    
      .top-amount-box {
        display: block !important;
      }
    
   
      .amount-container {
        display: flex !important;
        flex-direction: column !important;
        align-items: flex-end !important;
        width: fit-content !important;
        margin-left: auto !important;
      }
    
      .amount-boxes {
        width: 100% !important;
        display: flex !important;
        justify-content: flex-end !important;
        margin: 0 !important;
        padding: 0 !important;
      }
    
   
      .amount-boxes .px-3:first-child {
        text-align: left;
        padding-right: 20px !important;
    
        min-width: 120px !important;
      }
    
    
      .amount-boxes .px-3:last-child {
        text-align: right;
        min-width: 200px !important;
      }
      .po-details-main-page .d-flex.justify-content-end.po-details-top-cards-header.px-3 {
        display: flex !important;
        flex-direction: column-reverse !important;
        justify-content: flex-end !important;
        width: 100% !important;
        margin: 0 !important;
        padding: 0 !important;
      }
    
     
      .po-details-main-page .d-flex.justify-content-end.po-details-top-cards-header.px-3 > p:first-child {
        display: block !important;
        width: 100% !important;
        text-align: right !important;
       
        margin-top: 10px !important;
      }
    
      
      .d-flex.amount {
        display: flex !important;
        justify-content: flex-end !important;
        margin-left: auto !important;
        width: 100% !important;
       
       
      }
      .d-flex.amount .px-4 {
        text-align: left;
        margin-top: 30px;
        padding-right: 20px !important;
        min-width: 120px !important;
      }
      .d-flex.amount .numbered-amount {
        margin-top: -20px !important;
        text-align: right;
        min-width: 200px !important;
      }
    
      
      .amount-boxes {
        border-bottom: 1px solid #EBEBEB !important;
        padding-bottom: 10px !important;
      }

      .po-details-top-cards-content{
        line-height: 25px !important;
        font-size: 18px;
      }
    
    
      .po-details-main-page .d-flex.justify-content-end {
        margin-bottom: 0 !important;
        padding-bottom: 10px !important;
      }
  } */