
.react-select__indicators,
.react-select__control,
.react-select__value-container {
    height: 43px;
}

.react-select__placeholder {
    height: 27px;
}
