.WaitingRelease {
  color: #eac52d;
  background: #fcf6dd;
}

.Released {
  background: #e0f8f2;
  color: #43d5ae;
}

/* .press-release-table .rdt_TableCell{

  width: 50%;

} */

/* div[data-column-id="Description"] {
  padding-left: 0px !important;
}

div[data-column-id="Title"] {
  width: auto !important;
  padding-right: 0px !important;
} */


